import {
  makeStyles,
  Typography,
  FormHelperText,
  MenuItem,
  Box,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ContentTwoSided, LeftSide, RightSide } from "../core/ContentTwoSided";
import CotroliaFormControl from "../core/input/CotroliaFormControl";
import CotroliaInputLabel from "../core/input/CotroliaInputLabel";
import CotroliaSelect from "../core/input/CotroliaSelect";
import CotroliaTextField from "../core/input/CotroliaTextField";
import { CotroliaStepper, CotroliaStepperActions } from "../core/stepper";
import CotroliaAutocomplete from "../core/input/CotroliaAutocomplete";
import UploadButton from "../core/UploadButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import configurationService from "../../services/configuration.service";
import interventionService from "../../services/intervention.service";
import AppConstant from "../../utils/appConstant";

const useStyle = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
  },
  titleDesc: {
    color: theme.palette.text.secondary,
  },
  uploadContainer: {
    padding: `${theme.spacing(1.5)}px 0px 0px`,
  },
  mt: {
    marginTop: theme.spacing(2),
  },
}));

const emptyError = {
  error: false,
  msg: "",
};

const NewInterventionStepPart = ({
  values,
  updateValue,
  steps,
  activeStep,
  nextStep,
  previousStep,
  configuration,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const [partFrequency, setPartFrequency] = useState(null);
  const [partType, setPartType] = useState(null);
  const [typeError, setTypeError] = useState(emptyError);
  const [manufacturerError, setManufacturerError] = useState(emptyError);
  const [warrantyPrevInterError, setWarrantyPrevInterError] =
    useState(emptyError);
  const [refError, setRefError] = useState(emptyError);
  const [descriptionError, setDescriptionError] = useState(emptyError);

  useEffect(() => {
    if (configuration) {
      if (configuration.partFrequency) {
        setPartFrequency(configuration.partFrequency);
      }
      if (configuration.partType) {
        setPartType(configuration.partType);
      }
    }
  }, [configuration]);

  const setParentFiles = (childrenFiles) => {
    updateValue("part", "pictures", childrenFiles);
  };

  const handleWarantyChange = (e) => {
    const value = e.target.value;
    updateValue("part", "waranty", value);

    // Clear the value of prev inter if the warranty value is not "yes"
    if (value === AppConstant.warrantyNo) {
      updateValue("part", "warrantyPrevInter", {
        id: null,
        label: "",
      });
    }
  };

  const hasErrors = () => {
    return (
      typeError.error ||
      manufacturerError.error ||
      refError.error ||
      descriptionError.error
    );
  };

  const handleClick = () => {
    let hasError = false;

    if (values.type === "") {
      setTypeError({
        error: true,
        msg: "RequiredFieldTypeError",
      });
      hasError = true;
    }

    // [#112] Errror handling for warranty prev intervention
    if (
      values.waranty === AppConstant.warrantyYes &&
      (values.warrantyPrevInter.id === undefined ||
        values.warrantyPrevInter.id === null)
    ) {
      setWarrantyPrevInterError({
        error: true,
        msg: "RequiredFieldWarrantyPrevInterError",
      });
      hasError = true;
    }

    if (
      values.manufacturer.id === undefined ||
      values.manufacturer.id === null
    ) {
      setManufacturerError({
        error: true,
        msg: "RequiredFieldManufacturerError",
      });
      hasError = true;
    }

    if (values.description === "") {
      setDescriptionError({
        error: true,
        msg: "RequiredFieldDescriptionError",
      });
      hasError = true;
    }

    if (values.ref === "" && values.refNotVisible === false) {
      setRefError({
        error: true,
        msg: "RequiredFieldRefError",
      });
      hasError = true;
    } else if (values.ref !== "" && values.refNotVisible === true) {
      setRefError({
        error: true,
        msg: "RequiredFieldRefError2",
      });
      hasError = true;
    }

    if (!hasError) {
      nextStep();
    }
  };

  return (
    <>
      <Typography variant="h4" className={classes.title} align="center">
        {t("YourBrokenPart")}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.titleDesc}
        align="center"
      >
        {t("YourBrokenPartDesc")}
      </Typography>
      <CotroliaStepper steps={steps} activeStep={activeStep} />

      <ContentTwoSided>
        <LeftSide>
          {/* Waranty */}
          <CotroliaFormControl variant="filled" required>
            <CotroliaInputLabel id="waranty-label">
              {t("Waranty")}
            </CotroliaInputLabel>
            <CotroliaSelect
              id="waranty"
              labelId="waranty-label"
              aria-describedby="waranty-helper"
              value={values.waranty}
              onChange={handleWarantyChange}
            >
              <MenuItem value={1}>{t("Yes")}</MenuItem>
              <MenuItem value={2}>{t("No")}</MenuItem>
            </CotroliaSelect>
            <FormHelperText id="waranty-helper">
              {t("WarantyHelper")}
            </FormHelperText>
          </CotroliaFormControl>

          {/* Waranty Prev inter only displayed when we select warranty */}
          {values.waranty === AppConstant.warrantyYes && (
            <CotroliaFormControl
              variant="filled"
              required
              error={warrantyPrevInterError.error}
            >
              <CotroliaAutocomplete
                id="waranty-prev-inter"
                label={t("WarantyPrevInter")}
                value={values.warrantyPrevInter}
                setValue={(newValue) => {
                  if (newValue && newValue.id !== undefined) {
                    setWarrantyPrevInterError(emptyError);
                    updateValue("part", "warrantyPrevInter", newValue);

                    // On select, prefill part type, manufacturer and reference
                    interventionService
                      .getIntervention(newValue.id)
                      .then((res) => {
                        if (res.partType) {
                          updateValue("part", "type", res.partType.id);
                        }
                        if (res.partRef) {
                          updateValue("part", "ref", res.partRef);
                        }
                        if (res.manufacturer) {
                          updateValue("part", "manufacturer", res.manufacturer);
                        }
                      })
                      .catch((err) => {
                        console.error(
                          "Can’t get intervention information with id : " +
                            newValue.id
                        );
                      });
                  } else {
                    setWarrantyPrevInterError({
                      error: true,
                      msg: "RequiredFieldWarrantyPrevInterError",
                    });
                    updateValue("part", "warrantyPrevInter", {
                      id: null,
                      label: newValue,
                    });
                  }
                }}
                error={warrantyPrevInterError.error}
                getSuggestions={(value) =>
                  interventionService.listOldIntervention(value).then((res) => {
                    let ret = [];
                    Object.keys(res).forEach((key) => {
                      ret.push({ id: key, label: res[key] });
                    });
                    return ret;
                  })
                }
                required
                isSuggestionObject
                labelKey="label"
              />
              <FormHelperText id="waranty-prev-inter-helper">
                {warrantyPrevInterError.msg !== ""
                  ? t(warrantyPrevInterError.msg)
                  : t("WarantyPrevInterHelper")}
              </FormHelperText>
            </CotroliaFormControl>
          )}

          {/* Part type */}
          <CotroliaFormControl
            variant="filled"
            required
            error={typeError.error}
          >
            <CotroliaInputLabel id="type-label">
              {t("PartType")}
            </CotroliaInputLabel>
            <CotroliaSelect
              id="type"
              labelId="type-label"
              value={values.type}
              native
              onChange={(e) => {
                if (e.target.value !== "") {
                  setTypeError(emptyError);
                } else {
                  setTypeError({
                    error: true,
                    msg: "RequiredFieldTypeError",
                  });
                }
                updateValue("part", "type", e.target.value);
              }}
            >
              <option value="" />
              {partType &&
                Object.keys(partType).map((key) => (
                  <optgroup label={partType[key].label} key={key}>
                    {partType[key].values &&
                      Object.keys(partType[key].values).map((subkey) => (
                        <option key={subkey} value={subkey}>
                          {partType[key].values[subkey]}
                        </option>
                      ))}
                  </optgroup>
                ))}
            </CotroliaSelect>
            <FormHelperText variant="outlined">
              {t(typeError.msg)}
            </FormHelperText>
          </CotroliaFormControl>

          {/* Manufacturer */}
          <CotroliaFormControl error={manufacturerError.error}>
            <CotroliaAutocomplete
              id="manufacturer"
              label={t("PartManufacturer")}
              value={values.manufacturer}
              setValue={(newValue) => {
                if (newValue && newValue.id !== undefined) {
                  setManufacturerError(emptyError);
                  updateValue("part", "manufacturer", newValue);
                } else {
                  setManufacturerError({
                    error: true,
                    msg: "RequiredFieldManufacturerError",
                  });
                  updateValue("part", "manufacturer", {
                    id: "",
                    label: newValue,
                  });
                }
              }}
              error={manufacturerError.error}
              getSuggestions={(value) =>
                configurationService
                  .getDictionary("c_supercotrolia_manufacturer", "label", value)
                  .then((res) => {
                    let ret = [];
                    Object.keys(res).forEach((key) => {
                      ret.push({ id: key, label: res[key] });
                    });
                    return ret;
                  })
              }
              required
              isSuggestionObject
              labelKey="label"
            />
            <FormHelperText variant="outlined">
              {t(manufacturerError.msg)}
            </FormHelperText>
          </CotroliaFormControl>

          {/* Reference */}
          <CotroliaFormControl error={refError.error}>
            <CotroliaTextField
              id="ref"
              label={t("PartRef")}
              value={values.ref}
              required
              error={refError.error}
              onChange={(e) => {
                if (e.target.value === "" && values.refNotVisible === false) {
                  setRefError({
                    error: true,
                    msg: "RequiredFieldRefError",
                  });
                } else if (
                  e.target.value !== "" &&
                  values.refNotVisible === true  
                ) {
                  setRefError({
                    error: true,
                    msg: "RequiredFieldRefError2",
                  });
                } else {
                  setRefError(emptyError);
                }
                updateValue("part", "ref", e.target.value);
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={values.refNotVisible}
                  onChange={() => {
                    if (values.ref === "" && !values.refNotVisible === false) {
                      setRefError({
                        error: true,
                        msg: "RequiredFieldRefError",
                      });
                    } else if (
                      values.ref !== "" &&
                      !values.refNotVisible === true
                    ) {
                      values.ref = "";
                      setRefError({
                        error: false,
                        msg: "RequiredFieldRefError2",
                      });
                    } else {
                      setRefError(emptyError);
                    }
                    updateValue("part", "refNotVisible", !values.refNotVisible);
                  }}
                />
              }
              label={t("PartRefNotVisible")}
              variant="outlined"
            />
            <FormHelperText variant="outlined">
              {refError.error ? t(refError.msg) : t("PartRefHelper")}
            </FormHelperText>
          </CotroliaFormControl>
        </LeftSide>

        <RightSide>
          {/* Description */}
          <CotroliaFormControl error={descriptionError.error}>
            <CotroliaTextField
              id="description"
              label={t("PartDescription")}
              value={values.description}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setDescriptionError(emptyError);
                } else {
                  setDescriptionError({
                    error: true,
                    msg: "RequiredFieldDescriptionError",
                  });
                }
                updateValue("part", "description", e.target.value);
              }}
              required
              multiline
              rows={4}
              error={descriptionError.error}
            />
            <FormHelperText variant="outlined">
              {descriptionError.error
                ? t(descriptionError.msg)
                : t("PartDescriptionHelper")}
            </FormHelperText>
          </CotroliaFormControl>

          {/* Frequency */}
          <CotroliaFormControl variant="filled">
            <CotroliaInputLabel id="frequency-label">
              {t("PartFrequency")}
            </CotroliaInputLabel>
            <CotroliaSelect
              id="frequency"
              labelId="frequency-label"
              value={values.frequency}
              onChange={(e) => {
                updateValue("part", "frequency", e.target.value);
              }}
            >
              {partFrequency &&
                Object.keys(partFrequency).map((key) => (
                  <MenuItem value={key} key={key}>
                    {partFrequency[key]}
                  </MenuItem>
                ))}
            </CotroliaSelect>
          </CotroliaFormControl>

          {/* Client ref */}
          <CotroliaFormControl>
            <CotroliaTextField
              id="clientRef"
              label={t("PartClientRef")}
              value={values.clientRef}
              onChange={(e) => {
                updateValue("part", "clientRef", e.target.value);
              }}
            />
            <FormHelperText variant="outlined">
              {t("PartClientRefHelper")}
            </FormHelperText>
          </CotroliaFormControl>

          {/* Upload document */}
          <Box className={classes.uploadContainer}>
            <Typography variant="body1">{t("DocumentOrPicture")}</Typography>
            <Typography variant="caption">
              {t("DocumentOrPictureHelper")}
            </Typography>
            <Box className={classes.mt}>
              <UploadButton
                parentFiles={values.pictures}
                setParentFiles={setParentFiles}
              />
            </Box>
          </Box>
        </RightSide>
      </ContentTwoSided>
      <CotroliaStepperActions>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            previousStep();
          }}
          disabled={steps[activeStep] === steps[0]} // We disable the back button if we are in the first step
        >
          {t("GoBack")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          onClick={handleClick}
          disabled={hasErrors()}
        >
          {t("Continue")}
        </Button>
      </CotroliaStepperActions>
    </>
  );
};

export default NewInterventionStepPart;
