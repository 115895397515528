import React from "react";
import Wizard from "../../core/Wizard";
import { Button, Box, Typography, useTheme } from "@material-ui/core";
import CallMadeIcon from "@material-ui/icons/CallMade";
import { useMenu } from "../../../context/menu-context";

const WizardExample = () => {
  const theme = useTheme();
  const menu = useMenu();

  return (
    <Wizard title="Bien démarrer">
      <Typography variant="h4">
        Gérer votre équipe et votre organisation
      </Typography>
      <Typography variant="body1">
        Sur cette page vous pouvez gérer vos informations ainsi que vos
        interventions
      </Typography>
      <Box style={{ marginTop: theme.spacing(3.5) }}>
        <Button
          variant="contained"
          endIcon={<CallMadeIcon></CallMadeIcon>}
          style={{ color: theme.palette.primary.main }}
        >
          En savoir plus
        </Button>
        <Button
          variant="text"
          style={{
            color: theme.palette.primary.contrastText,
            marginLeft: menu.onSmartphone ? theme.spacing(0) : theme.spacing(3),
          }}
        >
          Données et confidentialité
        </Button>
      </Box>
    </Wizard>
  );
};

export default WizardExample;
