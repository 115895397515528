import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/auth-context";
import configurationService from "../../services/configuration.service";
import NewInterventionStepSkeleton from "../core/skeleton/NewInterventionStepSkeleton";
import NewInterventionStepPart from "./NewInterventionStepPart";
import NewInterventionStepShipping from "./NewInterventionStepShipping";
import NewInterventionStepPackaging from "./NewInterventionStepPackaging";
import NewInterventionStepFinish from "./NewInterventionStepFinish";
import AppConstant from "../../utils/appConstant";
import { formateInterventionData } from "../../utils/model";
import interventionService from "../../services/intervention.service";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ContentCard from "../core/layout/ContentCard";

const defaultSteps = ["StepPart", "StepDelivery", "StepPackage", "StepFinal"];

const NewInterventionForm = ({ values, updateValue }) => {
  const MAX_STEP = 4; // TODO : Load max step in function of entity type
  const MIN_STEP = 1; // TODO : Load min step in function of entity type
  const [step, setStep] = useState(MIN_STEP);
  const [steps] = useState(defaultSteps); // TODO : load steps in function of entity type
  const alert = useAlert();
  const [configuration, setConfiguration] = useState(null);
  const [loading, setLoading] = useState(true);
  const { fetchConnectedUser } = useAuth();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    // Load configuration informations
    configurationService
      .getInterConfiguration()
      .then((res) => {
        setConfiguration(res);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  /**
   * Change the actual step to the next one
   */
  const nextStep = () => {
    let value = step + 1;
    if (value <= MAX_STEP) {
      setStep(value);
    }
  };

  /**
   * Change the actual step to the previous one
   */
  const previousStep = () => {
    let value = step - 1;
    if (value >= MIN_STEP) {
      setStep(value);
    }
  };

  /**
   * Handle the intervention sending
   */
  const handleSend = async () => {
    const interData = formateInterventionData(
      values,
      AppConstant.interStatusTransmited
    );
    let uploadErr = 0;

    try {
      let res = await interventionService.postCreateIntervention(interData);

      if (res.id) {
        // Upload pictures
        for (let i = 0; i < interData.pictures.length; i++) {
          interventionService
            .uploadDocument(res.id, interData.pictures[i])
            .catch((err, uploadErr) => {
              console.error(err);
              uploadErr++;
            });
        }

        // Delete pictures
        if (
          values.part.oldpictures !== [] &&
          values.part.pictures !== values.part.oldpictures
        ) {
          for (let i = 0; i < values.part.oldpictures.length; i++) {
            if (!interData.pictures.includes(values.part.oldpictures[i])) {
              interventionService
                .deleteDocument(res.id, values.part.oldpictures[i])
                .catch((err, uploadErr) => {
                  console.error(err);
                  uploadErr++;
                });
            }
          }
        }
      }

      // Fetch me to get updated contact list and intervention numbers
      fetchConnectedUser();

      if (uploadErr === 0) {
        // Display feedback and redirect
        alert.show("", {
          title: t("CreateInterSuccess"),
          close: () => {
            alert.remove(alert);
          },
          type: "success",
        });
        history.push("/");
      } else {
        console.error("Number of files not uploaded : " + uploadErr);
        alert.show(t("FileNotUploadedDesc"), {
          title: t("FileNotUploaded"),
          close: () => {
            alert.remove(alert);
          },
          type: "warning",
        });
      }
    } catch (err) {
      console.error(err);
      alert.show(t("CreateInterErrorDesc"), {
        title: t("CreateInterError"),
        close: () => {
          alert.remove(alert);
        },
        type: "error",
      });
    }
  };

  /**
   * Display correct step to display.
   * @returns   Component
   */
  const displayStep = () => {
    let component;

    switch (step) {
      case 4:
        component = (
          <NewInterventionStepFinish
            values={values.finish}
            updateValue={updateValue}
            steps={steps}
            activeStep={step - 1}
            previousStep={previousStep}
            handleSend={handleSend}
          />
        );
        break;
      case 3:
        component = (
          <NewInterventionStepPackaging
            values={values.packaging}
            updateValue={updateValue}
            steps={steps}
            activeStep={step - 1}
            previousStep={previousStep}
            nextStep={nextStep}
            configuration={configuration}
            shippingMethod={values.shipping.method}
          />
        );
        break;
      case 2:
        component = (
          <NewInterventionStepShipping
            values={values.shipping}
            updateValue={updateValue}
            steps={steps}
            activeStep={step - 1}
            previousStep={previousStep}
            nextStep={nextStep}
            configuration={configuration}
          />
        );
        break;
      case 1:
      default:
        component = (
          <NewInterventionStepPart
            values={values.part}
            updateValue={updateValue}
            steps={steps}
            activeStep={step - 1}
            previousStep={previousStep}
            nextStep={nextStep}
            configuration={configuration}
          />
        );
        break;
    }

    return component;
  };

  return (
    <ContentCard>
      {loading ? <NewInterventionStepSkeleton /> : displayStep()}
    </ContentCard>
  );
};

export default NewInterventionForm;
