import React, { useState } from "react";

import {
  Typography,
  Box,
  FormHelperText,
  Divider,
  Link,
  Chip,
} from "@material-ui/core";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";

import SyntaxHighlighter from "react-syntax-highlighter";
import { rainbow } from "react-syntax-highlighter/dist/esm/styles/hljs";

import Header from "../../core/Header";
import Content from "../../core/layout/Content";
import ContentCard from "../../core/layout/ContentCard";

import CotroliaFormControl from "../../core/input/CotroliaFormControl";
import DocumentationPropsTable from "../DocumentationPropsTable";
import { categoryDefinition } from "../DocumentationConstant";
import CotroliaAddress from "../../core/input/CotroliaAddress";

const inputExampleSimple = `
const Component = () => {
    
    const [address, setAddress] = useState("");
    
    return (
      <CotroliaFormControl>
        <CotroliaAddress
          label="Adresse"
          updateAddress={(value) => setAddress(value)}
        />
      </CotroliaFormControl>
    );
}
`;

const errorExample = `
const Component = () => {
  return (
    <CotroliaFormControl>
      <CotroliaAddress
        label="Adresse"
        updateAddress={updateAddress}
        error={true}
        errorMsg="Veuillez saisir une adresse valide"
      />
    </CotroliaFormControl>
  );
};
`;

const DocAddress = () => {
  const [address, setAddress] = useState("");

  return (
    <>
      <Header
        focus={true}
        title="Composant"
        titleIcon={<DeveloperModeIcon />}
        titleDesc="<CotroliaAddress />"
      ></Header>
      <Content>
        <ContentCard>
          <Typography variant="h5">
            CotroliaAddress : Le composant de saisie d'adresse
          </Typography>
          <Box style={{ margin: "1rem 0 1rem 0" }}>
            <Chip
              label="Inputs"
              icon={categoryDefinition["Inputs"].icon}
              variant="outlined"
              color="primary"
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography variant="h5">🚀 Présentation</Typography>
            <Typography variant="subtitle1">
              <p>
                Ce composant est utilisé pour saisir des adresses dans
                l'application. Il est composé d'un champ d'auto-complétion
                d'adresse et d'une checkbox dans le cas où l'adresse est
                introuvable. Si l'adresse est introuvable, on a alors un
                formulaire de saisie d'adresse sans auto-complétion.
                L'auto-complétion est basée sur l'api du gouvernement Français.
                ⚠️ Le composant devra évoluer lors du passage de Cotrolia à
                l'international.
              </p>
            </Typography>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">📦 Emplacement</Typography>

            <Typography variant="subtitle1">
              <p>
                Ce composant est disponible dans le dossier suivant :{" "}
                <div style={{ overflowX: "auto", width: "100%" }}>
                  <code>src/components/core/input/CotroliaAddress.jsx</code>
                </div>
              </p>
            </Typography>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">🔬 Utilisation basique</Typography>

            <Typography variant="subtitle1">
              <p>
                Le composant peut être utilisé avec un minimum de propriétés. Au
                minimum, il n'a besoin que du libellé et de la fonction pour
                modifier la valeur du parent.
              </p>
            </Typography>

            <CotroliaFormControl>
              <CotroliaAddress
                label="Adresse"
                updateAddress={(value) => setAddress(value)}
              />
              <FormHelperText>
                L'adresse saisie est : {JSON.stringify(address)}
              </FormHelperText>
            </CotroliaFormControl>

            <SyntaxHighlighter language="javascript" style={rainbow}>
              {inputExampleSimple}
            </SyntaxHighlighter>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">🔬 Affichage des erreurs</Typography>

            <Typography variant="subtitle1">
              <p>
                Il possible d'afficher le champ en erreur avec les propriétés{" "}
                <code>error</code> et <code>errorMsg</code>.
              </p>
            </Typography>

            <CotroliaFormControl>
              <CotroliaAddress
                label="Adresse"
                updateAddress={() => {}}
                error={true}
                errorMsg="Veuillez saisir une adresse valide"
              />
            </CotroliaFormControl>

            <SyntaxHighlighter language="javascript" style={rainbow}>
              {errorExample}
            </SyntaxHighlighter>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">🚨 Règles d'implémentation</Typography>
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Le composant doit toujours être dans un CotroliaFormControl
                </Typography>
              </li>
            </ul>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">💻 Propriétés</Typography>
            <DocumentationPropsTable
              list={[
                {
                  name: "label",
                  type: "string",
                  default: "",
                  desc: "Le libellé du champ",
                },
                {
                  name: "updateAddress",
                  type: "func",
                  default: "",
                  desc: "La méthode permettant de changer la valeur du parent",
                },
                {
                  name: "required",
                  type: "bool",
                  default: "false",
                  desc: "Défini si le champs est requis ou non",
                },
                {
                  name: "defaultAddress",
                  type: "object",
                  default: "emptyAddress",
                  desc: "L'objet de l'adresse par défaut (Voir les constantes)",
                },
                {
                  name: "error",
                  type: "bool",
                  default: "false",
                  desc: "Défini si une erreur est présente ou non",
                },
                {
                  name: "errorMsg",
                  type: "string",
                  default: "",
                  desc: "Le message d'erreur affiché si error est à true",
                },
              ]}
            />

            <Typography variant="subtitle1">
              <p>
                {" "}
                En plus des props ci-dessus, le composant hérite de toutes les
                props du composant{" "}
                <Link
                  href="https://v4.mui.com/api/text-field/#props"
                  target="_blank"
                >
                  TextField
                </Link>{" "}
                de Material UI.
              </p>
            </Typography>
          </Box>
        </ContentCard>
      </Content>
    </>
  );
};

export default DocAddress;
