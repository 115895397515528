import React, { useState } from "react";

import {
  Typography,
  Box,
  Divider,
  Link,
  Chip,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";

import SyntaxHighlighter from "react-syntax-highlighter";
import { rainbow } from "react-syntax-highlighter/dist/esm/styles/hljs";

import Header from "../../core/Header";
import Content from "../../core/layout/Content";
import ContentCard from "../../core/layout/ContentCard";

import { categoryDefinition } from "../DocumentationConstant";
import CotroliaFormControl from "../../core/input/CotroliaFormControl";
import CotroliaInputLabel from "../../core/input/CotroliaInputLabel";
import CotroliaSelect from "../../core/input/CotroliaSelect";

const inputExampleSimple = `
const Component = () => {

  const [value, setValue] = useState(1);

  return (
    <CotroliaFormControl variant="filled" required>
        <CotroliaInputLabel id="field-label">Garantie</CotroliaInputLabel>
        <CotroliaSelect
          id="field"
          labelId="field-label"
          aria-describedby="field-helper"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          <MenuItem value={1}>Oui</MenuItem>
          <MenuItem value={2}>Non</MenuItem>
        </CotroliaSelect>
        <FormHelperText id="field-helper">
          S'agit t'il d'une garantie ?
        </FormHelperText>
      </CotroliaFormControl>
  );
}
`;

const DocInputLabel = () => {
  const [value, setValue] = useState(1);
  return (
    <>
      <Header
        focus={true}
        title="Composant"
        titleIcon={<DeveloperModeIcon />}
        titleDesc="<CotroliaInputLabel />"
      ></Header>
      <Content>
        <ContentCard>
          <Typography variant="h5">
            CotroliaInputLabel : Le composant libellé pour certains champs
          </Typography>
          <Box style={{ margin: "1rem 0 1rem 0" }}>
            <Chip
              label="Inputs"
              icon={categoryDefinition["Inputs"].icon}
              variant="outlined"
              color="primary"
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography variant="h5">🚀 Présentation</Typography>
            <Typography variant="subtitle1">
              <p>
                Ce composant est utilisé pour fournir un libellé aux champs qui
                ne peuvent en contenir. Il doit être utilisé en combinaison dans
                un CotroliaFormControl. Le composant est identique à celui de
                Material UI en ajoutant seulement du css pour appliquer la
                couleur principale.
              </p>
            </Typography>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">📦 Emplacement</Typography>

            <Typography variant="subtitle1">
              <p>
                Ce composant est disponible dans le dossier suivant :{" "}
                <div style={{ overflowX: "auto", width: "100%" }}>
                  <code>src/components/core/input/CotroliaInputLabel.jsx</code>
                </div>
              </p>
            </Typography>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">🔬 Utilisation basique</Typography>

            <Typography variant="subtitle1">
              <p></p>
            </Typography>

            <CotroliaFormControl variant="filled" required>
              <CotroliaInputLabel id="field-label">Garantie</CotroliaInputLabel>
              <CotroliaSelect
                id="field"
                labelId="field-label"
                aria-describedby="field-helper"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              >
                <MenuItem value={1}>Oui</MenuItem>
                <MenuItem value={2}>Non</MenuItem>
              </CotroliaSelect>
              <FormHelperText id="field-helper">
                S'agit t'il d'une garantie ?
              </FormHelperText>
            </CotroliaFormControl>

            <SyntaxHighlighter language="javascript" style={rainbow}>
              {inputExampleSimple}
            </SyntaxHighlighter>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">🚨 Règles d'implémentation</Typography>
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Le composant doit toujours être dans un CotroliaFormControl
                </Typography>
              </li>
            </ul>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">💻 Propriétés</Typography>

            <Typography variant="subtitle1">
              <p>
                Le composant hérite de toutes les props du composant{" "}
                <Link
                  href="https://v4.mui.com/api/input-label/#inputlabel-api"
                  target="_blank"
                >
                  InputLabel
                </Link>{" "}
                de Material UI.
              </p>
            </Typography>
          </Box>
        </ContentCard>
      </Content>
    </>
  );
};

export default DocInputLabel;
