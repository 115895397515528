import React from "react";

import {
  Typography,
  Box,
  Divider,
  Link,
  Chip,
  FormHelperText,
} from "@material-ui/core";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";

import SyntaxHighlighter from "react-syntax-highlighter";
import { rainbow } from "react-syntax-highlighter/dist/esm/styles/hljs";

import Header from "../../core/Header";
import Content from "../../core/layout/Content";
import ContentCard from "../../core/layout/ContentCard";

import { categoryDefinition } from "../DocumentationConstant";
import CotroliaFormControl from "../../core/input/CotroliaFormControl";
import CotroliaTextField from "../../core/input/CotroliaTextField";

const inputExampleSimple = `
const Component = () => {
    return (
      <CotroliaFormControl>
        <CotroliaTextField
          label="Adresse email"
          aria-describedby="my-helper-text"
        />
        <FormHelperText id="my-helper-text">
          On ne la partagera jamais.
        </FormHelperText>
      </CotroliaFormControl>
    );
}
`;

const DocFormControl = () => {
  return (
    <>
      <Header
        focus={true}
        title="Composant"
        titleIcon={<DeveloperModeIcon />}
        titleDesc="<CotroliaFormControl />"
      ></Header>
      <Content>
        <ContentCard>
          <Typography variant="h5">
            CotroliaFormControl : Le composant container de tous les inputs
          </Typography>
          <Box style={{ margin: "1rem 0 1rem 0" }}>
            <Chip
              label="Inputs"
              icon={categoryDefinition["Inputs"].icon}
              variant="outlined"
              color="primary"
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography variant="h5">🚀 Présentation</Typography>
            <Typography variant="subtitle1">
              <p>
                Ce composant est utilisé comme container à un inputs. Il permet
                de fournir un context tel que error / focused / required /
                filled à un groupe de champ. Le composant est identique à celui
                de Material UI en ajoutant seulement du css pour prendre 100% de
                largeur. ⚠️ Il ne peut y avoir qu'un seul champ dans un
                FormControl
              </p>
            </Typography>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">📦 Emplacement</Typography>

            <Typography variant="subtitle1">
              <p>
                Ce composant est disponible dans le dossier suivant :{" "}
                <div style={{ overflowX: "auto", width: "100%" }}>
                  <code>src/components/core/input/CotroliaFormControl.jsx</code>
                </div>
              </p>
            </Typography>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">🔬 Utilisation basique</Typography>

            <Typography variant="subtitle1">
              <p>
                Voici un exemple complet de ce que peut contenir un FormControl
                (exemple tiré de la documentation de Material UI).
              </p>
            </Typography>

            <CotroliaFormControl>
              <CotroliaTextField
                label="Adresse email"
                aria-describedby="my-helper-text"
              />
              <FormHelperText id="my-helper-text">
                On ne la partagera jamais.
              </FormHelperText>
            </CotroliaFormControl>

            <SyntaxHighlighter language="javascript" style={rainbow}>
              {inputExampleSimple}
            </SyntaxHighlighter>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">🚨 Règles d'implémentation</Typography>
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Un CotroliaFormControl ne doit contenir qu'un seul input{" "}
                </Typography>
              </li>
            </ul>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">💻 Propriétés</Typography>

            <Typography variant="subtitle1">
              <p>
                Le composant hérite de toutes les props du composant{" "}
                <Link
                  href="https://v4.mui.com/api/form-control/#formcontrol-api"
                  target="_blank"
                >
                  FormControl
                </Link>{" "}
                de Material UI.
              </p>
            </Typography>
          </Box>
        </ContentCard>
      </Content>
    </>
  );
};

export default DocFormControl;
