import React, { useEffect, useState } from "react";

import { Box, Typography, Link, Divider } from "@material-ui/core";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import FindInPageIcon from "@material-ui/icons/FindInPage";

import Header from "../core/Header";
import Content from "../core/layout/Content";
import ContentCard from "../core/layout/ContentCard";
import ToggleSection from "../core/ToggleSection";
import DocumentationSearch from "./DocumentationSearch";
import { componentList, categoryDefinition } from "./DocumentationConstant";

const Documentation = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let categoriesFormated = [];

    componentList.forEach((component) => {
      let category = component.category;
      let categoryIndex = categoriesFormated.findIndex(
        (elem) => elem.name === category
      );
      if (categoryIndex >= 0) {
        categoriesFormated[categoryIndex]["components"].push(component);
      } else {
        categoriesFormated.push({
          name: category,
          components: [component],
          ...categoryDefinition[category],
        });
      }
    });

    setCategories(categoriesFormated);
  }, []);

  return (
    <>
      <Header
        focus={false}
        title="Documentation"
        titleIcon={<DeveloperModeIcon />}
        titleDesc="Liste des composants"
      ></Header>
      <Content>
        <ContentCard>
          <DocumentationSearch />
          {categories.map((category) => (
            <ToggleSection
              title={category.name}
              icon={category.icon}
              description={category.description}
              key={`category-${category.name}`}
            >
              {category.components.map((component, index) => (
                <React.Fragment
                  key={`category-${category.name}-component-${component.name}`}
                >
                  <Box style={{ display: "flex", padding: "1rem" }}>
                    <Box style={{ flex: 1 }}>
                      <Typography variant="body1">{component.name}</Typography>
                      <Typography variant="body2">
                        {component.description}
                      </Typography>
                    </Box>
                    {component.available ? (
                      <Link
                        href={`/components${component.url}`}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <FindInPageIcon />
                        <Typography variant="body2">
                          Voir la documentation
                        </Typography>
                      </Link>
                    ) : (
                      <Typography variant="caption">
                        ⚠️ Documentation non disponible
                      </Typography>
                    )}
                  </Box>
                  {index + 1 !== category.components.length && <Divider />}
                </React.Fragment>
              ))}
            </ToggleSection>
          ))}
        </ContentCard>
      </Content>
    </>
  );
};

export default Documentation;
