import React from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import RegisterForm from "./RegisterForm";
import LoginRegisterLeftContainer from "./LoginRegisterLeftContainer";
import LoginRegisterDivider from "./LoginRegisterDivider";
import LoginForm from "./LoginForm";
import ForgetPasswordForm from "./ForgetPasswordForm";

const useStyle = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      width: "100%",
      padding: "0",
      margin: "0",
      display: "block",
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

const LoginOrRegister = ({ variant }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const registerMode = variant === "register";
  const forgetMode = variant === "forget-password";

  const dislpayRightForm = () => {
    if (registerMode && !forgetMode) {
      return <RegisterForm />;
    } else if (forgetMode) {
      return <ForgetPasswordForm />;
    }
    return <LoginForm />;
  };

  return (
    <Container className={classes.root}>
      <Box className={classes.container}>
        {registerMode ? (
          <LoginRegisterLeftContainer
            cardTitle={t("AlreadyHaveAccount")}
            cardRedirect="/login"
            cardBtnLabel={t("Login")}
          />
        ) : (
          <LoginRegisterLeftContainer
            cardTitle={t("DontHaveAccount")}
            cardRedirect="/register"
            cardBtnLabel={t("Register")}
          />
        )}

        <LoginRegisterDivider />

        {dislpayRightForm()}
      </Box>
    </Container>
  );
};

export default LoginOrRegister;
