import {
  makeStyles,
  Typography,
  MenuItem,
  Button,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentTwoSided, LeftSide, RightSide } from "../core/ContentTwoSided";
import CotroliaFormControl from "../core/input/CotroliaFormControl";
import CotroliaInputLabel from "../core/input/CotroliaInputLabel";
import CotroliaSelect from "../core/input/CotroliaSelect";
import CotroliaTextField from "../core/input/CotroliaTextField";
import { CotroliaStepper, CotroliaStepperActions } from "../core/stepper";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CotroliaDatePicker from "../core/input/CotroliaDatePicker";
import { useAuth } from "../../context/auth-context";
import { useEffect } from "react";
import CotroliaAddress from "../core/input/CotroliaAddress";
import AppConstant from "../../utils/appConstant";
import { validPhoneNumber } from "../../utils/validation";

const useStyle = makeStyles((theme) => ({
  primaryColor: {
    color: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.text.primary,
  },
  titleDesc: {
    color: theme.palette.text.secondary,
  },
  uploadContainer: {
    padding: `${theme.spacing(1.5)}px 0px 0px`,
  },
  mt: {
    marginTop: theme.spacing(2),
  },
  inputBackground: {
    width: "100%",
    borderRadius: theme.spacing(1) + 2,
    backgroundColor: theme.palette.grey[50],
    "& > *": {
      padding: theme.spacing(1.5),
    },
  },
}));

const emptyError = {
  error: false,
  msg: "",
};

const NewInterventionStepShipping = ({
  values,
  updateValue,
  steps,
  activeStep,
  nextStep,
  previousStep,
  configuration,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const [displayPickupDate, setDisplayPickupDate] = useState(true);
  const minPickupDate = values.pickupDate;
  const [dateError, setDateError] = useState(emptyError);
  const [componentDateError, setComponentDateError] = useState(false);
  const [companyError, setCompanyError] = useState(emptyError);
  const [phoneError, setPhoneError] = useState(emptyError);
  const [addressError, setAddressError] = useState(emptyError);
  const { user } = useAuth();

  useEffect(() => {
    if (parseInt(values.method) === AppConstant.shippingMethodPersonal) {
      setDisplayPickupDate(false);
    }
  }, [values.method]);

  useEffect(() => {
    let address = values.address;
    if (address.unknown) {
      // We need to check each fields for unknown address
      if (
        address.street === "" ||
        address.city === "" ||
        address.zipCode === "" ||
        address.countryCode === ""
      ) {
        setAddressError({
          error: true,
          msg: t("RequiredFieldAddressError"),
        });
      } else {
        setAddressError(emptyError);
      }
    } else {
      // We need to only check fullAddress
      // [#401] Check if France is the only data set in the default fullAddress
      if (address.fullAddress === "" || address.fullAddress === "France") {
        setAddressError({
          error: true,
          msg: t("RequiredFieldAddressError"),
        });
      } else {
        setAddressError(emptyError);
      }
    }
  }, [t, values.address]);

  const handleMethodChange = (e) => {
    const value = e.target.value;
    updateValue("shipping", "method", value);

    // We display the date only if the user choose the picking
    if (value === AppConstant.shippingMethodPicking) {
      setDisplayPickupDate(true);
    } else {
      setDisplayPickupDate(false);
      setComponentDateError(false);
      setDateError(emptyError);
    }
  };

  const handleDateChange = (date) => {
    if (date !== null) {
      setDateError(emptyError);
      updateValue("shipping", "pickupDate", date);
    } else {
      updateValue("shipping", "pickupDate", "");
      setDateError({
        error: true,
        msg: "RequiredFieldDateError",
      });
    }
  };

  const hasErrors = () => {
    return (
      componentDateError ||
      dateError.error ||
      companyError.error ||
      addressError.error ||
      phoneError.error
    );
  };

  const handleClick = () => {
    let hasError = false;

    if (componentDateError) {
      hasError = true;
    }

    if (
      values.pickupDate === null &&
      parseInt(values.method) === AppConstant.shippingMethodPicking
    ) {
      setDateError({
        error: true,
        msg: "RequiredFieldDateError",
      });
      hasError = true;
    }

    if (values.company === "") {
      setCompanyError({
        error: true,
        msg: "RequiredFieldCompanyError",
      });
      hasError = true;
    }

    if (
      values.phone === "") {
      setPhoneError({
        error: true,
        msg: "RequiredFieldPhoneError",
      });
      hasError = true;
    } else if (!validPhoneNumber(values.phone)) {
      setPhoneError({
        error: true,
        msg: "NotValidPhoneNumber",
      });
      hasError = true;
    }

    if (values.address.fullAddress === "") {
      setAddressError({
        error: true,
        msg: "RequiredFieldAddressError",
      });
      hasError = true;
    }

    if (!hasError) {
      nextStep();
    }
  };

  return (
    <>
      <Typography variant="h4" className={classes.title} align="center">
        {t("StepShippingTitle")}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.titleDesc}
        align="center"
      >
        {t("StepShippingDesc")}
      </Typography>
      <CotroliaStepper steps={steps} activeStep={activeStep} />

      <ContentTwoSided>
        <LeftSide>
          {/* Shipping method */}
          <CotroliaFormControl variant="filled" required>
            <CotroliaInputLabel id="method-label">
              {t("DeliveryMethod")}
            </CotroliaInputLabel>
            <CotroliaSelect
              id="method"
              labelId="method-label"
              value={values.method}
              onChange={handleMethodChange}
            >
              {user.data.individual !== true && (
                <MenuItem value={AppConstant.shippingMethodPicking}>
                  {t("PickingMethod")}
                </MenuItem>
              )}
              <MenuItem value={AppConstant.shippingMethodPersonal}>
                {t("PersonalMethod")}
              </MenuItem>
              <MenuItem value={AppConstant.shippingMethodDesk}>
                {t("DeskMethod")}
              </MenuItem>
            </CotroliaSelect>
          </CotroliaFormControl>

          {/* Shipping date */}
          {displayPickupDate && (
            <CotroliaFormControl error={dateError.error || componentDateError}>
              <CotroliaDatePicker
                required
                label={t("PickupDate")}
                value={values.pickupDate}
                onChange={handleDateChange}
                minDate={minPickupDate}
                error={dateError.error || componentDateError}
                onError={(err, value) => {
                  if (err !== "") {
                    setComponentDateError(true);
                  } else {
                    setComponentDateError(false);
                  }
                }}
              />
              <FormHelperText variant="outlined">
                {t(dateError.msg)}
              </FormHelperText>
            </CotroliaFormControl>
          )}

          {/* Company */}
          <CotroliaFormControl error={companyError.error}>
            <CotroliaTextField
              id="company"
              label={t("YourInformations")}
              value={values.company}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setCompanyError(emptyError);
                } else {
                  setCompanyError({
                    error: true,
                    msg: "RequiredFieldCompanyError",
                  });
                }
                updateValue("shipping", "company", e.target.value);
              }}
              required
              error={companyError.error}
            />
            <FormHelperText variant="outlined">
              {companyError.error
                ? t(companyError.msg)
                : t("ShippingCompanyHelper")}
            </FormHelperText>
          </CotroliaFormControl>
        {/*  [#185] Display phone number on every ship method */}
          {/* Phone */}
            <CotroliaFormControl error={phoneError.error}>
              <CotroliaTextField
                id="phone"
                label={t("ShippingPhone")}
                value={values.phone}
                required
                onChange={(e) => {
                  const value = e.target.value;
                  if (value !== "") {
                    setPhoneError(emptyError);
                  } else {
                    setPhoneError({
                      error: true,
                      msg: "RequiredFieldPhoneError",
                    });
                  }
                  updateValue("shipping", "phone", e.target.value);
                }}
                error={phoneError.error}
              />
              <FormHelperText variant="outlined">
                {t(phoneError.msg)}
              </FormHelperText>
            </CotroliaFormControl>
          {(parseInt(values.method) === AppConstant.shippingMethodPersonal ||
            parseInt(values.method) === AppConstant.shippingMethodDesk) && (
            <CotroliaAddress
              label={t("ShippingAddress")}
              updateAddress={(value) =>
                updateValue("shipping", "address", value)
              }
              defaultAddress={values.address}
              required
              error={addressError.error}
              errorMsg={addressError.msg}
            />
          )}

          <CotroliaFormControl>
            <CotroliaTextField
              label={t("RecipientCoord")}
              value={configuration.entityAddress}
              disabled
            />
          </CotroliaFormControl>
        </LeftSide>
        
        {/* Informations (only "professional" can change this information */}
        {parseInt(values.method) === AppConstant.shippingMethodPicking && (
          <RightSide>
            <>
              <CotroliaFormControl>
                <CotroliaTextField
                  id="informations"
                  label={t("ShippingInformations")}
                  helperText={t("ShippingInformationsHelper")}
                  value={values.informations}
                  onChange={(e) =>
                    updateValue("shipping", "informations", e.target.value)
                  }
                />
              </CotroliaFormControl>

              {/* Address */}
              <CotroliaAddress
                label={t("ShippingAddress")}
                updateAddress={(value) =>
                  updateValue("shipping", "address", value)
                }
                defaultAddress={values.address}
                required
                error={addressError.error}
                errorMsg={addressError.msg}
              />
              {/* Closing days */}
              <CotroliaFormControl additionalClass={classes.inputBackground}>
                <FormLabel className={classes.primaryColor}>
                  {t("ClosingDays")}
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.closingDates.monday}
                        onChange={(e) => {
                          let newValue = { ...values.closingDates };
                          newValue.monday = !newValue.monday;
                          updateValue("shipping", "closingDates", newValue);
                        }}
                        name="monday"
                        color="primary"
                      />
                    }
                    label={t("Monday")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.closingDates.saturday}
                        onChange={(e) => {
                          let newValue = { ...values.closingDates };
                          newValue.saturday = !newValue.saturday;
                          updateValue("shipping", "closingDates", newValue);
                        }}
                        name="saturday"
                        color="primary"
                      />
                    }
                    label={t("Saturday")}
                  />
                </FormGroup>
              </CotroliaFormControl>
            </>
          </RightSide>
        )}
      </ContentTwoSided>
      <CotroliaStepperActions>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            previousStep();
          }}
          disabled={steps[activeStep] === steps[0]} // We disable the back button if we are in the first step
        >
          {t("GoBack")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          onClick={handleClick}
          disabled={hasErrors()}
        >
          {t("Continue")}
        </Button>
      </CotroliaStepperActions>
    </>
  );
};

export default NewInterventionStepShipping;
