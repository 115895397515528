import React, { useState } from "react";

import { Typography, Box, Divider, Link, Chip } from "@material-ui/core";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";

import SyntaxHighlighter from "react-syntax-highlighter";
import { rainbow } from "react-syntax-highlighter/dist/esm/styles/hljs";

import Header from "../../core/Header";
import Content from "../../core/layout/Content";
import ContentCard from "../../core/layout/ContentCard";

import { categoryDefinition } from "../DocumentationConstant";
import CotroliaDatePicker from "../../core/input/CotroliaDatePicker";
import CotroliaFormControl from "../../core/input/CotroliaFormControl";
import moment from "moment";

const inputExampleSimple = `
const Component = () => {
    const [date, setDate] = useState(moment.now());
    
    return (
      <CotroliaFormControl>
        <CotroliaDatePicker
          label="Sélectionner une date"
          value={date}
          onChange={setDate}
          minDate={moment.now()}
        />
      </CotroliaFormControl>
    );
}
`;

const DocDatePicker = () => {
  const [date, setDate] = useState(moment.now());

  return (
    <>
      <Header
        focus={true}
        title="Composant"
        titleIcon={<DeveloperModeIcon />}
        titleDesc="<CotroliaDatePicker />"
      ></Header>
      <Content>
        <ContentCard>
          <Typography variant="h5">
            CotroliaDatePicker : Le composant de saisie de date
          </Typography>
          <Box style={{ margin: "1rem 0 1rem 0" }}>
            <Chip
              label="Inputs"
              icon={categoryDefinition["Inputs"].icon}
              variant="outlined"
              color="primary"
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography variant="h5">🚀 Présentation</Typography>
            <Typography variant="subtitle1">
              <p>
                Ce champs permet de sélectionner une date. Le format de ce champ
                est DD/MM/YYYY. On utilise la librairie{" "}
                <Link href="https://momentjs.com/" target="_blank">
                  moment.js
                </Link>{" "}
                pour gérer les dates et les formats de date pour les valeurs.
              </p>
            </Typography>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">📦 Emplacement</Typography>

            <Typography variant="subtitle1">
              <p>
                Ce composant est disponible dans le dossier suivant :{" "}
                <div style={{ overflowX: "auto", width: "100%" }}>
                  <code>src/components/core/input/CotroliaDatePicker.jsx</code>
                </div>
              </p>
            </Typography>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">🔬 Utilisation basique</Typography>

            <Typography variant="subtitle1">
              <p>
                L'utilisation la plus basique est de fournir un libellé, une
                valeur (obligatoirement une date) et la fonction de callback qui
                sera appelée lorsque l'utilisateur valide la saisie. Par défaut,
                il y a une gestion d'erreur sur le format de la date si il ne
                respecte pas le format "DD/MM/YYYY".
              </p>
            </Typography>

            <CotroliaFormControl>
              <CotroliaDatePicker
                label="Sélectionner une date"
                value={date}
                onChange={setDate}
                minDate={moment.now()}
              />
            </CotroliaFormControl>

            <SyntaxHighlighter language="javascript" style={rainbow}>
              {inputExampleSimple}
            </SyntaxHighlighter>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">🚨 Règles d'implémentation</Typography>
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Le composant doit toujours être dans un CotroliaFormControl
                </Typography>
              </li>
            </ul>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">💻 Propriétés</Typography>

            <Typography variant="subtitle1">
              <p>
                Le composant hérite de toutes les props du composant{" "}
                <Link
                  href="https://material-ui-pickers.dev/api/KeyboardDatePicker"
                  target="_blank"
                >
                  KeyboardDatePicker
                </Link>{" "}
                de Material UI.
              </p>
            </Typography>
          </Box>
        </ContentCard>
      </Content>
    </>
  );
};

export default DocDatePicker;
