import React from "react";

import { Typography, Box, Divider, Chip, Link } from "@material-ui/core";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";

import SyntaxHighlighter from "react-syntax-highlighter";
import { rainbow } from "react-syntax-highlighter/dist/esm/styles/hljs";

import Header from "../../core/Header";
import Content from "../../core/layout/Content";
import ContentCard from "../../core/layout/ContentCard";

import { categoryDefinition } from "../DocumentationConstant";

const exampleSimple = `
const Component = () => {
    return (
      <Content>
        <ContentCard>
          This is the page content
        </ContentCard>
      </Content>
    );
}
`;

const exampleWithoutPadding = `
const Component = () => {
    return (
      <Content withoutPadding>
        <ContentCard>
          This is the page content
        </ContentCard>
      </Content>
    );
}
`;

const DocContentCard = () => {
  return (
    <>
      <Header
        focus={true}
        title="Composant"
        titleIcon={<DeveloperModeIcon />}
        titleDesc="<ContentCard />"
      ></Header>
      <Content>
        <ContentCard>
          <Typography variant="h5">
            ContentCard : Le composant pour appliquer un style de carte au
            contenu
          </Typography>
          <Box style={{ margin: "1rem 0 1rem 0" }}>
            <Chip
              label="Layout"
              icon={categoryDefinition["Layout"].icon}
              variant="outlined"
              color="primary"
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography variant="h5">🚀 Présentation</Typography>
            <Typography variant="subtitle1">
              <p>
                Ce composant permet d'appliquer un style de carte au contenu
                d'une page. Il doit être utilisé dans le composant{" "}
                <code>Content</code> lorsque le contenu doit être affiché avec
                un fond particulier. Ce composant est très simple et ne possède
                pas de propriétés.
              </p>
            </Typography>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">📦 Emplacement</Typography>

            <Typography variant="subtitle1">
              <p>
                Ce composant est disponible dans le dossier suivant :{" "}
                <div style={{ overflowX: "auto", width: "100%" }}>
                  <code>src/components/core/layout/ContentCard.jsx</code>
                </div>
              </p>
            </Typography>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">🔬 Utilisation basique</Typography>

            <Typography variant="subtitle1">
              <p>
                Couplé au un composant <code>Content</code>, on aura un rendu
                comme le suivant.
              </p>
            </Typography>

            <Box style={{ width: "100%", height: "200px" }}>
              <Content>
                <ContentCard>This is the page content</ContentCard>
              </Content>
            </Box>

            <SyntaxHighlighter language="javascript" style={rainbow}>
              {exampleSimple}
            </SyntaxHighlighter>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">🔬 Sans padding</Typography>

            <Typography variant="subtitle1">
              <p>
                Couplé au un composant <code>Content</code> sans padding, on
                aura un rendu comme le suivant.
              </p>
            </Typography>

            <Box style={{ width: "100%", height: "200px" }}>
              <Content withoutPadding>
                <ContentCard>This is the page content</ContentCard>
              </Content>
            </Box>

            <SyntaxHighlighter language="javascript" style={rainbow}>
              {exampleWithoutPadding}
            </SyntaxHighlighter>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">🚨 Règles d'implémentation</Typography>
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Le composant doit toujours être dans un composant{" "}
                  <code>Content</code>
                </Typography>
              </li>
            </ul>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">💻 Propriétés</Typography>

            <Typography variant="subtitle1">
              <p>
                Le composant hérite de toutes les props du composant{" "}
                <Link
                  href="https://v4.mui.com/components/box/#api"
                  target="_blank"
                >
                  Box
                </Link>{" "}
                de Material UI.
              </p>
            </Typography>
          </Box>
        </ContentCard>
      </Content>
    </>
  );
};

export default DocContentCard;
