import React from "react";

import { Typography, Box, Divider, Chip } from "@material-ui/core";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";

import SyntaxHighlighter from "react-syntax-highlighter";
import { rainbow } from "react-syntax-highlighter/dist/esm/styles/hljs";

import Header from "../../core/Header";
import Content from "../../core/layout/Content";
import ContentCard from "../../core/layout/ContentCard";

import DocumentationPropsTable from "../DocumentationPropsTable";
import { categoryDefinition } from "../DocumentationConstant";

const exampleSimple = `
const Component = () => {
    return (
      <Content>
        This is the page content
      </Content>
    );
}
`;

const exampleWithoutPadding = `
const Component = () => {
    return (
      <Content withoutPadding>
        This is the page content
      </Content>
    );
}
`;

const DocContent = () => {
  return (
    <>
      <Header
        focus={true}
        title="Composant"
        titleIcon={<DeveloperModeIcon />}
        titleDesc="<Content />"
      ></Header>
      <Content>
        <ContentCard>
          <Typography variant="h5">
            Content : Le composant conteneur de toutes les pages
          </Typography>
          <Box style={{ margin: "1rem 0 1rem 0" }}>
            <Chip
              label="Layout"
              icon={categoryDefinition["Layout"].icon}
              variant="outlined"
              color="primary"
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography variant="h5">🚀 Présentation</Typography>
            <Typography variant="subtitle1">
              <p>
                Ce composant est le container à utiliser pour le contenu de
                chaques pages. En fonction des écrans, il peut être utilisé avec
                ou sans padding.
              </p>
            </Typography>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">📦 Emplacement</Typography>

            <Typography variant="subtitle1">
              <p>
                Ce composant est disponible dans le dossier suivant :{" "}
                <div style={{ overflowX: "auto", width: "100%" }}>
                  <code>src/components/core/layout/Content.jsx</code>
                </div>
              </p>
            </Typography>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">🔬 Utilisation basique</Typography>

            <Typography variant="subtitle1">
              <p>
                Le plus simple est d'utiliser le composant sans paramètres. Par
                défaut, on aura du padding sur tous les côtés.
              </p>
            </Typography>

            <Box style={{ width: "100%", height: "200px" }}>
              <Content>This is the page content</Content>
            </Box>

            <SyntaxHighlighter language="javascript" style={rainbow}>
              {exampleSimple}
            </SyntaxHighlighter>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">🔬 Sans padding</Typography>

            <Typography variant="subtitle1">
              <p>
                Il est aussi possible d'enlever le padding à gauche et à droite
                avec la propriété <code>withoutPadding</code>. Le padding sur le
                haut et le bas du conteneur resteront présents.
              </p>
            </Typography>

            <Box style={{ width: "100%", height: "200px" }}>
              <Content withoutPadding>This is the page content</Content>
            </Box>

            <SyntaxHighlighter language="javascript" style={rainbow}>
              {exampleWithoutPadding}
            </SyntaxHighlighter>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">🚨 Règles d'implémentation</Typography>
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Le composant Content est génèralement précédé du composant
                  Header
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Le composant Content est souvent couplé au composant
                  ContentCard
                </Typography>
              </li>
            </ul>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">💻 Propriétés</Typography>
            <DocumentationPropsTable
              list={[
                {
                  name: "withoutPadding",
                  type: "bool",
                  default: "false",
                  desc: "Défini si le container est avec ou sans padding",
                },
                {
                  name: "children",
                  type: "node",
                  default: "",
                  desc: "Le contenu de la page",
                },
              ]}
            />
          </Box>
        </ContentCard>
      </Content>
    </>
  );
};

export default DocContent;
