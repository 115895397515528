import React from "react";

import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

import Documentation from "../example/Documentation";
import DocAddress from "../example/input/DocAddress";
import DocAutocomplete from "../example/input/DocAutocomplete";
import DocDatePicker from "../example/input/DocDatePicker";
import DocFormControl from "../example/input/DocFormControl";
import DocInputLabel from "../example/input/DocInputLabel";
import DocSelect from "../example/input/DocSelect";
import DocContent from "../example/layout/DocContent";
import DocContentCard from "../example/layout/DocContentCard";
import OldListComponents from "../example/old/OldList";

const ListComponents = () => {
  return (
    <BrowserRouter basename="/components">
      <Switch>
        <Route exact path="/">
          <Documentation />
        </Route>

        <Route exact path="/old">
          <OldListComponents />;
        </Route>

        {/* Inputs routes */}
        <Route exact path="/input/cotrolia-address">
          <DocAddress />
        </Route>
        <Route exact path="/input/cotrolia-autocomplete">
          <DocAutocomplete />
        </Route>
        <Route exact path="/input/cotrolia-date-picker">
          <DocDatePicker />
        </Route>
        <Route exact path="/input/cotrolia-form-control">
          <DocFormControl />
        </Route>
        <Route exact path="/input/cotrolia-input-label">
          <DocInputLabel />
        </Route>
        <Route exact path="/input/cotrolia-select">
          <DocSelect />
        </Route>

        {/* Skeletons routes */}

        {/* Layout routes */}
        <Route exact path="/layout/content">
          <DocContent />
        </Route>
        <Route exact path="/layout/content-card">
          <DocContentCard />
        </Route>

        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default ListComponents;
