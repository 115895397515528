import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import UploadButton from "../../core/UploadButton";
import SendIcon from "@material-ui/icons/Send";
import { useMenu } from "../../../context/menu-context";
import AppConstant from "../../../utils/appConstant";
import { useTheme } from "@material-ui/styles";
import { useAlert } from "react-alert";

const UploadExample = () => {
  const menu = useMenu();
  const theme = useTheme();
  const alert = useAlert();
  const [files, setFiles] = useState(null);
  const [display, setDisplay] = useState(false);
  const [width, setWidth] = useState(null);

  useEffect(() => {
    let drawerWidth = 0;
    let paddingWidth = 0;
    if (menu.onSmartphone) {
      drawerWidth = menu.isOpen ? "" : "0"; // TODO : manage this calc
      paddingWidth = theme.spacing(13);
    } else {
      drawerWidth = menu.isOpen
        ? AppConstant.drawerWidth
        : AppConstant.drawerWidthClose;
      paddingWidth = theme.spacing(36);
    }
    setWidth(`calc(100vw - ${drawerWidth}px - ${paddingWidth}px)`);
  }, [menu.isOpen, menu.onSmartphone, theme]);

  const handleClick = () => {
    console.log(files);
    alert.show("La console affiche la liste des fichiers qui sont chargés", {
      title: "Regarde la console js",
      close: () => {
        alert.remove(alert);
      },
      type: "success",
    });
    setDisplay(true);
  };

  const displayFiles = () => {
    return (
      files &&
      files.map((item, index) => {
        let url = URL.createObjectURL(item);

        if (item.type === "application/pdf") {
          url =
            "https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-15.png";
        }

        return (
          <Card style={{ margin: "0 .5em", minWidth: "200px" }}>
            <CardMedia>
              <img key={index} src={url} alt="Preview" height="200" />
            </CardMedia>
            <CardContent>
              <Typography align="center">{item.name}</Typography>
            </CardContent>
          </Card>
        );
      })
    );
  };

  return (
    <>
      <UploadButton parentFiles={files} setParentFiles={setFiles} />
      <Box style={{ marginTop: "1em" }}>
        <Box
          style={{
            display: "flex",
            overflowX: "scroll",
            flexWrap: "nowrap",
            width: width,
          }}
        >
          {display && displayFiles()}
        </Box>
        <Button
          variant="contained"
          color="primary"
          endIcon={<SendIcon />}
          onClick={handleClick}
        >
          Simuler l'envoi
        </Button>
      </Box>
    </>
  );
};

export default UploadExample;
